body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.home-section{
  box-shadow :  rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px;
  width: 70%;
  margin:auto;
}
.box-class{
  margin:auto;
  height: auto;
  width:200px;
  border:1px solid gray;
  border-top-left-radius:20px ;
  border-top-right-radius:20px ;
  position:relative;
  margin-bottom:10px;
}

.box-class-button {

}
.box-class-button .btn {
  width: 80px;
}
.btnDanger{
  float: right;
}
.count-section{
  height: 200px;
  width:200px;
}
.count{
  display: flex;
  text-align: center;
  justify-content: center;
}

.count {
  width: 200px;
  height: 150px;
  line-height: 150px;
  font-size: 3em;
  text-align: center;
  
}

.input-section{
  text-align: center;
  width: 40%;
  margin: auto;
  margin-top:3%;
  margin-bottom:3%;

}
.input-section input{
  text-align: center;
}
.resetBtn{
  float: right;

}

.header-section{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.input-course-section{
  border: 1px solid gray;
  padding:10px;
  border-radius: 10px;
}
.header-sec{
  display: flex;
  justify-content: space-between;
}
.csv-upload-section{

}
.csv-upload{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.csv-reader-input{
  margin: auto;
  margin-top: 20px;
}